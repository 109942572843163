<template>
  <div class="pt-180">
    <center>
      <h1 style="color: white;margin-top: 20%;">404 not found</h1>
      <h2 style="color: white" >it seems you're in the wrong page</h2>
      <div class="back-to-login">
        <router-link to="/" replace>BACK TO LOGIN</router-link>
      </div>
    </center>

    
  </div>
</template>
<script>
export default {
  name: 'PageNotFound',
}
</script>

